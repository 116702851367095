<template>
    <div class="app-container">
        <div class="company-container">
            <el-form ref="distributeConfig" :model="distributeConfig" label-position="left" label-width="150px">
                <el-form-item label="分拣员考勤配置">
                    <div class="box">
                        <el-input v-model="distributeConfig.late_time" type="number" min="0" placeholder="请输入迟到时间(分钟)" class="input">
                            <template slot="prepend">迟到时间(分钟)</template>
                        </el-input>
                        <el-input v-model="distributeConfig.late_amount" type="number" min="0" placeholder="请输入迟到扣除金额" class="input">
                            <template slot="prepend">迟到扣除金额</template>
                        </el-input>
                        <el-input v-model="distributeConfig.late_absenteeism" type="number" min="0" placeholder="请输入迟到多少为旷工(分钟)" class="input">
                            <template slot="prepend">迟到多少为旷工(分钟)</template>
                        </el-input>
                    </div>

                    <div class="box">
                        <el-input v-model="distributeConfig.early_leave_time" type="number" min="0" placeholder="请输入早退时间(分钟)" class="input">
                            <template slot="prepend">早退时间(分钟)</template>
                        </el-input>
                        <el-input v-model="distributeConfig.early_leave_amount" type="number" min="0" placeholder="请输入早退扣除金额" class="input">
                            <template slot="prepend">早退扣除金额</template>
                        </el-input>
                        <el-input v-model="distributeConfig.early_leave_absenteeism" type="number" min="0" placeholder="请输入早退多少为旷工(分钟)" class="input">
                            <template slot="prepend">早退多少为旷工(分钟)</template>
                        </el-input>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="saveDistributeConfig" :loading="btnDistributeLoading">保存</el-button>
                </el-form-item>
            </el-form>

            <el-form ref="sendConfig" :model="sendConfig" label-position="left" label-width="150px">
                <el-form-item label="配送员考勤配置">
                    <div class="box">
                        <el-input v-model="sendConfig.late_time" type="number" min="0" placeholder="请输入迟到时间(分钟)" class="input">
                            <template slot="prepend">迟到时间(分钟)</template>
                        </el-input>
                        <el-input v-model="sendConfig.late_amount" type="number" min="0" placeholder="请输入迟到扣除金额" class="input">
                            <template slot="prepend">迟到扣除金额</template>
                        </el-input>
                        <el-input v-model="sendConfig.late_absenteeism" type="number" min="0" placeholder="请输入迟到多少为旷工(分钟)" class="input">
                            <template slot="prepend">迟到多少为旷工(分钟)</template>
                        </el-input>
                    </div>

                    <div class="box">
                        <el-input v-model="sendConfig.early_leave_time" type="number" min="0" placeholder="请输入早退时间(分钟)" class="input">
                            <template slot="prepend">早退时间(分钟)</template>
                        </el-input>
                        <el-input v-model="sendConfig.early_leave_amount" type="number" min="0" placeholder="请输入早退扣除金额" class="input">
                            <template slot="prepend">早退扣除金额</template>
                        </el-input>
                        <el-input v-model="sendConfig.early_leave_absenteeism" type="number" min="0" placeholder="请输入早退多少为旷工(分钟)" class="input">
                            <template slot="prepend">早退多少为旷工(分钟)</template>
                        </el-input>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="saveSendConfig" :loading="btnSendLoading">保存</el-button>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                // 分拣员考勤配置
                btnDistributeLoading: false,
                distributeQuery: {
                    school_id: '',
                    role_type: 1,
                },
                distributeConfig: {
                    school_id: '',
                    role_type: 1,
                    late_time: '',
                    late_amount: '',
                    late_absenteeism: '',
                    early_leave_time: '',
                    early_leave_amount: '',
                    early_leave_absenteeism: '',
                },
                // 配送员考勤配置
                btnSendLoading: false,
                sendQuery: {
                    school_id: '',
                    role_type: 2,
                },
                sendConfig: {
                    school_id: '',
                    role_type: 2,
                    late_time: '',
                    late_amount: '',
                    late_absenteeism: '',
                    early_leave_time: '',
                    early_leave_amount: '',
                    early_leave_absenteeism: '',
                },
            };
        },
        created() {
            this.getDistributeConfig();
            this.getSendConfig();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.distributeQuery.school_id = newVal;
                this.getDistributeConfig();
                this.sendQuery.school_id = newVal;
                this.getSendConfig();
            },
        },
        methods: {
            getDistributeConfig() {
                this.distributeQuery.school_id = this.school_id;
                request({
                    url: "/api/backend/attendanceConfig/get",
                    method: "get",
                    params: this.distributeQuery
                }).then(response => {
                    if (response.data) {
                        this.distributeConfig = response.data
                    } else  {
                        this.resetDistributeConfig()
                    }
                });
            },
            resetDistributeConfig() {
                this.distributeConfig = {
                    school_id: '',
                    role_type: 1,
                    late_time: '',
                    late_amount: '',
                    late_absenteeism: '',
                    early_leave_time: '',
                    early_leave_amount: '',
                    early_leave_absenteeism: '',
                }
                this.btnDistributeLoading = false
            },
            saveDistributeConfig(){
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.distributeConfig.school_id = this.school_id;
                }
                if (this.distributeConfig.late_time === '' || this.distributeConfig.late_time < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到时间",
                    });
                    return;
                }
                if (this.distributeConfig.late_amount === '' || this.distributeConfig.late_amount < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到扣除金额",
                    });
                    return;
                }
                if (this.distributeConfig.late_absenteeism === '' || this.distributeConfig.late_absenteeism < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到多少为旷工",
                    });
                    return;
                }

                if (this.distributeConfig.early_leave_time === '' || this.distributeConfig.early_leave_time < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退时间",
                    });
                    return;
                }
                if (this.distributeConfig.early_leave_amount === '' || this.distributeConfig.early_leave_amount < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退扣除金额",
                    });
                    return;
                }
                if (this.distributeConfig.early_leave_absenteeism === '' || this.distributeConfig.early_leave_absenteeism < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退多少为旷工",
                    });
                    return;
                }

                this.btnDistributeLoading = true
                request({
                    url: '/api/backend/attendanceConfig/set',
                    method: 'post',
                    data: this.distributeConfig
                }).then(response => {
                    this.btnDistributeLoading = false
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.getDistributeConfig()
                }).catch((err) => {
                    this.btnDistributeLoading = false
                })
            },

            getSendConfig() {
                this.sendQuery.school_id = this.school_id;
                request({
                    url: "/api/backend/attendanceConfig/get",
                    method: "get",
                    params: this.sendQuery
                }).then(response => {
                    if (response.data) {
                        this.sendConfig = response.data
                    } else  {
                        this.resetSendConfig()
                    }
                });
            },
            resetSendConfig() {
                this.sendConfig = {
                    school_id: '',
                    role_type: 2,
                    late_time: '',
                    late_amount: '',
                    late_absenteeism: '',
                    early_leave_time: '',
                    early_leave_amount: '',
                    early_leave_absenteeism: '',
                }
                this.btnSendLoading = false
            },
            saveSendConfig(){
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.sendConfig.school_id = this.school_id;
                }
                if (this.sendConfig.late_time === '' || this.sendConfig.late_time < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到时间",
                    });
                    return;
                }
                if (this.sendConfig.late_amount === '' || this.sendConfig.late_amount < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到扣除金额",
                    });
                    return;
                }
                if (this.sendConfig.late_absenteeism === '' || this.sendConfig.late_absenteeism < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入迟到多少为旷工",
                    });
                    return;
                }

                if (this.sendConfig.early_leave_time === '' || this.sendConfig.early_leave_time < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退时间",
                    });
                    return;
                }
                if (this.sendConfig.early_leave_amount === '' || this.sendConfig.early_leave_amount < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退扣除金额",
                    });
                    return;
                }
                if (this.sendConfig.early_leave_absenteeism === '' || this.sendConfig.early_leave_absenteeism < 0) {
                    this.$message({
                        type: "warning",
                        message: "请输入早退多少为旷工",
                    });
                    return;
                }

                this.btnSendLoading = true
                request({
                    url: '/api/backend/attendanceConfig/set',
                    method: 'post',
                    data: this.sendConfig
                }).then(response => {
                    this.btnSendLoading = false
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.getSendConfig()
                }).catch((err) => {
                    this.btnSendLoading = false
                })
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss">
    .box {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .input {
            width: 32%;
            margin-right: 2%;
        }
        .input:nth-of-type(3n) {
            margin-right: 0;
        }
    }
    .box:nth-of-type(1) {
        margin-top: 0;
    }
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }

</style>
