import { render, staticRenderFns } from "./config.vue?vue&type=template&id=9a992230&scoped=true&"
import script from "./config.vue?vue&type=script&lang=js&"
export * from "./config.vue?vue&type=script&lang=js&"
import style0 from "./config.vue?vue&type=style&index=0&rel=stylesheet%2Fscss&lang=scss&"
import style1 from "./config.vue?vue&type=style&index=1&id=9a992230&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a992230",
  null
  
)

export default component.exports